<template>
  <!-- Buttons -->
  <HorizontalScrollWrapper
    ref="scrollWrapperRef"
    class="relative flex h-full items-center justify-between"
  >
    <LoadingWrapper compact :loading>
      <div class="flex gap-2">
        <BaseButton
          v-for="asset in assets"
          :key="asset.id"
          color="secondary"
          small
          :class="{ 'pointer-events-none': !clickable }"
          @click="onAssetClicked(asset)"
        >
          <StatusIndicatorComponent :asset no-tooltip />
          <span class="mx-1">{{ asset.name }}</span>
        </BaseButton>
      </div>
      <div
        v-if="showSearch"
        class="sticky right-0 -ml-2 h-full place-content-center bg-gradient-to-r from-white/0 via-white via-20% to-white pl-8 pr-4 md:pr-10"
      >
        <BaseButton color="secondary" small @click="onSearchClicked">
          <BaseIcon name="search" class="text-lg text-gray-900" />
        </BaseButton>
      </div>
    </LoadingWrapper>
  </HorizontalScrollWrapper>
</template>

<script setup>
import { ref, watch } from "vue"
import { useRoute, useRouter } from "vue-router"
import useFilterStore from "@/stores/filterStore.js"
import {
  BaseIcon,
  BaseButton,
  LoadingWrapper,
} from "@repowerednl/ui-component-library"
import StatusIndicatorComponent from "@/components/information/StatusIndicatorComponent.vue"
import HorizontalScrollWrapper from "@/components/utilities/HorizontalScrollWrapper.vue"

defineProps({
  assets: {
    type: Array,
    required: true,
  },
  clickable: Boolean,
  loading: Boolean,
  showSearch: Boolean,
})

const route = useRoute()
const router = useRouter()
const filterStore = useFilterStore()
const scrollWrapperRef = ref()

async function onAssetClicked(asset) {
  await router.push({ name: "assets-analysis" })
  filterStore.setMeteringPoint(asset)
}

function onSearchClicked() {
  router.push({ name: "assets-analysis", query: { search: null } })
}

// Reset asset bar scroll position when the route changes
watch(
  () => route.name,
  () => {
    if (scrollWrapperRef.value) {
      scrollWrapperRef.value.resetPosition()
    }
  },
)
</script>
