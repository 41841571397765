<template>
  <FilterMenuComponent
    ref="filterMenuRef"
    v-model="filterStore.currentPortfolio"
    :items="portfolioItems"
    :search-string-callback="(item) => item.value.queryString"
    use-cards
    @confirmed="onPortfolioSelection"
  >
    <span v-if="filterStore.currentPortfolio" class="truncate">
      {{ filterStore.currentPortfolio.name }}
    </span>
    <span v-else class="text-primary-200">Kies een portfolio</span>
    <template #title> Portfolio's </template>
    <template
      v-for="portfolio in meteringPointsStore.portfolios"
      :key="portfolio.id"
      #[portfolio.name]
    >
      <div class="flex items-center justify-between">
        <div class="flex items-center gap-2 truncate">
          <BaseIcon name="briefcase-4" filled class="flex-shrink-0 text-lg" />
          <TextLabel class="truncate">{{ portfolio.name }}</TextLabel>
        </div>
        <span
          v-if="portfolioAssets(portfolio)"
          class="flex-shrink-0 text-sm text-gray-400"
        >
          {{ portfolioAssets(portfolio).length }}
          asset{{ portfolioAssets(portfolio).length !== 1 ? "s" : "" }}
        </span>
      </div>
      <AssetsBarComponent
        v-if="portfolioAssets(portfolio)"
        :assets="portfolioAssets(portfolio)"
        class="-mx-3 mt-2 px-3 text-gray-900"
      />
    </template>
  </FilterMenuComponent>
</template>

<script setup>
import { ref, computed } from "vue"
import HTTP_STATUS from "@/helpers/httpStatusHelper.js"
import useMeteringPointsStore from "@/stores/repower/meteringPointsStore.js"
import useFilterStore from "@/stores/filterStore.js"
import useNotificationStore from "@/stores/notificationStore.js"
import { BaseIcon, TextLabel } from "@repowerednl/ui-component-library"
import FilterMenuComponent from "@/components/input/FilterMenuComponent.vue"
import AssetsBarComponent from "@/components/navigation/AssetsBarComponent.vue"

const filterMenuRef = ref()
const meteringPointsStore = useMeteringPointsStore()
const filterStore = useFilterStore()
const notificationStore = useNotificationStore()

const portfolioItems = computed(() =>
  meteringPointsStore.portfolios.map((portfolio) => {
    return {
      label: portfolio.name,
      value: portfolio,
    }
  }),
)

function onLoadFail(error) {
  if (error.code === HTTP_STATUS.CONFLICT) {
    notificationStore.pushError(
      "Fout bij het laden van assets",
      `De opgehaalde data komt niet overeen. Neem contact op met Repowered. (code: ${error.code})`,
      "load-portfolio-assets-error",
    )
  } else {
    notificationStore.pushError(
      "Fout bij het laden van assets",
      `De assets van dit portfolio konden niet worden opgehaald. Probeer het later opnieuw. (code: ${error.code})`,
      "load-portfolio-assets-error",
    )
  }
}

function onPortfolioSelection(selectedPortfolio) {
  if (!selectedPortfolio?.id) {
    return
  }
  selectedPortfolio.loadPortfolioAssets(null, onLoadFail)
}

function portfolioAssets(portfolio) {
  return meteringPointsStore.assetsByPortfolio[portfolio.id]
}

defineExpose({
  openMenu() {
    filterMenuRef.value.openMenu()
  },
})
</script>
