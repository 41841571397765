<template>
  <BaseCard class="max-h-[720px] overflow-x-auto">
    <LoadingWrapper
      v-if="meteringPoint"
      :error="loadingFailed"
      :loading="loading"
    >
      <BaseTable
        :grouped-columns="groupColumns"
        :columns="columns"
        :rows="rows"
        :precision="PRECISION"
        class="min-h-24"
      >
        <!-- Add header tooltips -->
        <template
          v-for="({ label, wikiPath }, key, index) in columns"
          :key="label"
          #[key]="{ header }"
        >
          {{ header }}
          <WikiTooltipComponent
            v-if="wikiPath"
            class="top-0.5"
            :path="wikiPath"
            :position="
              index === Object.keys(columns).length - 1
                ? 'bottom left'
                : 'bottom'
            "
          />
        </template>
        <!-- Bold total column -->
        <template #TOTAL-cell="{ row }">
          <div class="font-bold text-gray-500">
            {{ formatNumber(row.TOTAL, PRECISION) }}
          </div>
        </template>
        <template #TOTAL-summary="{ row }">
          <div class="font-bold text-gray-500">
            {{ formatNumber(row.TOTAL, PRECISION) }}
          </div>
        </template>
        <!-- Columns with extra precision -->
        <template
          v-for="column in precisionColumns"
          :key="column"
          #[`${column}-cell`]="{ row }"
        >
          {{
            Object.hasOwn(row, column)
              ? formatNumber(row[column], PRECISION_EXTRA)
              : ""
          }}
        </template>
        <template
          v-for="column in precisionColumns"
          :key="column"
          #[`${column}-summary`]="{ row }"
        >
          {{
            Object.hasOwn(row, column)
              ? formatNumber(row[column], PRECISION_EXTRA)
              : ""
          }}
        </template>
        <!-- Italic summary cell -->
        <template #datetime-summary="{ row }">
          <span class="italic">
            {{ row.datetime }}
          </span>
        </template>
        <!-- Format dates and add badges to datetime cells -->
        <template #datetime-cell="{ row }">
          <div class="flex gap-1">
            <span>{{ format(row.datetime, dateFormat[step]) }}</span>
            <BaseBadge
              v-if="isToday(row.datetime) && step !== 'minute'"
              color="primary"
            >
              Vandaag
            </BaseBadge>
            <BaseBadge
              v-else-if="!row.stable"
              class="!bg-energy-50 !text-energy-900"
            >
              Voorlopig
            </BaseBadge>
          </div>
        </template>
      </BaseTable>
    </LoadingWrapper>
    <div v-else class="flex items-center justify-center p-4 italic md:p-6">
      <p>Maak een selectie om gegevens te kunnen bekijken</p>
    </div>
  </BaseCard>
</template>

<script setup>
import { computed, ref, watch } from "vue"
import {
  differenceInCalendarDays,
  differenceInCalendarMonths,
  format,
  isToday,
} from "date-fns"
import { debounce, DEBOUNCE_DELAY } from "@/helpers/debounce.js"
import { formatNumber } from "@/services/formatterService.js"
import useFilterStore from "@/stores/filterStore.js"
import useNotificationStore from "@/stores/notificationStore.js"
import {
  BaseTable,
  BaseBadge,
  BaseCard,
  LoadingWrapper,
} from "@repowerednl/ui-component-library"
import WikiTooltipComponent from "@/components/information/WikiTooltipComponent.vue"

const props = defineProps({
  /**
   * Can be either an asset or a portfolio, but no data is shown for portfolios.
   */
  meteringPoint: {
    type: Object,
    default: null,
  },
  /**
   * Array of two Date objects; the last day is included in the range.
   */
  dateRange: {
    type: Array,
    required: true,
  },
  /**
   * Determines which data series are shown/hidden.
   */
  mode: {
    required: true,
    validator: (value) => ["energy", "finance", "both"].includes(value),
  },
})

const DAYS_MINUTE = 5
const DAYS_DAY = 32
const MONTHS_YEAR = 25
const PRECISION = 2
const PRECISION_EXTRA = 5

const filterStore = useFilterStore()
const notificationStore = useNotificationStore()
const recordsData = ref()
const loading = ref(false)
const loadingFailed = ref(false)

/**
 * Columns that are hidden in the different modes.
 */
const modeHiddenColumns = {
  energy: ["€", "€/kWh"],
  finance: ["kWh"],
  both: [],
}

const freq = {
  minute: "15min",
  day: "1D",
  month: "MS",
  year: "YS",
}

const dateFormat = {
  minute: "PPp",
  day: "PP",
  month: "MMMM y",
  year: "y",
}

const step = computed(() => {
  const days =
    1 + differenceInCalendarDays(props.dateRange[1], props.dateRange[0])
  const months =
    1 + differenceInCalendarMonths(props.dateRange[1], props.dateRange[0])
  if (days < DAYS_MINUTE) {
    return "minute"
  } else if (days < DAYS_DAY) {
    return "day"
  } else if (months < MONTHS_YEAR) {
    return "month"
  } else {
    return "year"
  }
})

const orderedGroupMapping = {
  Elektriciteit: [
    "ALLOCATION_POINT_FEED_IN_VOLUME",
    "ALLOCATION_POINT_TAKE_OFF_VOLUME",
    "ASSET_FEED_IN_VOLUME",
    "ASSET_TAKE_OFF_VOLUME",
  ],
  Dagmarkt: [
    "DAY_AHEAD_FEED_IN_VOLUME",
    "DAY_AHEAD_FEED_IN_PRICE",
    "DAY_AHEAD_FEED_IN_RESULT",
    "DAY_AHEAD_TAKE_OFF_VOLUME",
    "DAY_AHEAD_TAKE_OFF_PRICE",
    "DAY_AHEAD_TAKE_OFF_RESULT",
  ],
  Onbalansmarkt: [
    "PORTFOLIO_IMBALANCE_FEED_IN_VOLUME",
    "PORTFOLIO_IMBALANCE_FEED_IN_PRICE",
    "PORTFOLIO_IMBALANCE_FEED_IN_RESULT",
    "PORTFOLIO_IMBALANCE_TAKE_OFF_VOLUME",
    "PORTFOLIO_IMBALANCE_TAKE_OFF_PRICE",
    "PORTFOLIO_IMBALANCE_TAKE_OFF_RESULT",
    "ALLOCATION_POINT_IMBALANCE_FEED_IN_VOLUME",
    "ALLOCATION_POINT_IMBALANCE_FEED_IN_PRICE",
    "ALLOCATION_POINT_IMBALANCE_FEED_IN_RESULT",
    "ALLOCATION_POINT_IMBALANCE_TAKE_OFF_VOLUME",
    "ALLOCATION_POINT_IMBALANCE_TAKE_OFF_PRICE",
    "ALLOCATION_POINT_IMBALANCE_TAKE_OFF_RESULT",
    "ASSET_IMBALANCE_FEED_IN_VOLUME",
    "ASSET_IMBALANCE_FEED_IN_PRICE",
    "ASSET_IMBALANCE_FEED_IN_RESULT",
    "ASSET_IMBALANCE_TAKE_OFF_VOLUME",
    "ASSET_IMBALANCE_TAKE_OFF_PRICE",
    "ASSET_IMBALANCE_TAKE_OFF_RESULT",
  ],
  "Vergoeding Diensten": [
    // "FIXED_FEE_PRICE", // not needed
    "FIXED_FEE_RESULT",
    "ENERGY_SERVICE_FEE",
    // "FIXED_FEE_VOLUME", //not needed
  ],
  "Resultaat optimalisatie zon": [
    "SMART_ENERGY_SOLAR_FEED_IN_VOLUME",
    "SMART_ENERGY_SOLAR_TAKE_OFF_VOLUME",
    "SMART_ENERGY_SOLAR_FEED_IN_RESULT",
    "SMART_ENERGY_SOLAR_TAKE_OFF_RESULT",
    "SMART_ENERGY_SOLAR_FEE",
  ],
  "Resultaat optimalisatie batterij": [
    "SMART_ENERGY_BATTERY_FEED_IN_VOLUME",
    "SMART_ENERGY_BATTERY_TAKE_OFF_VOLUME",
    "SMART_ENERGY_BATTERY_FEED_IN_RESULT",
    "SMART_ENERGY_BATTERY_TAKE_OFF_RESULT",
    "SMART_ENERGY_BATTERY_FEE",
  ],
  Overig: ["ENERGY_TAX", "PORTFOLIO_BENEFIT", "MISSED_GVO", "MISSED_SDE"],
  Totaal: ["TOTAL_RESULT"],
}

const partialResultColumns = [
  "DAY_AHEAD_FEED_IN_RESULT",
  "DAY_AHEAD_TAKE_OFF_RESULT",
  "PORTFOLIO_IMBALANCE_FEED_IN_RESULT",
  "PORTFOLIO_IMBALANCE_TAKE_OFF_RESULT",
  "FIXED_FEE_RESULT",
  "ENERGY_SERVICE_FEE",
  "SMART_ENERGY_SOLAR_FEE",
  "SMART_ENERGY_BATTERY_FEE",
]

const columns = computed(() => {
  if (!recordsData.value) {
    return {}
  }

  const sortedStandardColumns = Object.fromEntries(
    Object.values(getGroupedColumns())
      .flat()
      .map(({ type: recordType, ...rest }) => [recordType, rest]),
  )

  const dateEntry = { datetime: { label: "Datum", classes: "", wikiPath: "" } }
  return { ...dateEntry, ...sortedStandardColumns }
})

const groupColumns = computed(() => {
  if (filterStore.useLegacyResults || !recordsData.value) {
    return null
  }
  const groupedColumns = getGroupedColumns()
  const dateGroup = { "": [] }
  return { ...dateGroup, ...groupedColumns }
})

/**
 * - Adds the group that it belongs to (see orderedGroupMapping)
 * - Sets the Dutch label with its unit
 * - Sets the correct classes; hidden if the selected mode
 *   (energy/financial/both) does not include the unit. Otherwise, CSS classes
 * - Sorts the record types according to the orderedGroupMapping
 *
 * Example Before: {
 *  "ALLOCATION_POINT_FEED_IN_VOLUME": {
 *    "unit": "kWh",
 *    "billable": true,
 *    "label_nl": "Teruglevering elektriciteit",
 *    "label_en": "Electricity feed in"
 *  },
 *  "ALLOCATION_POINT_TAKE_OFF_VOLUME": {
 *    "unit": "kWh",
 *    "billable": true,
 *    "label_nl": "Levering elektriciteit",
 *    "label_en": "Electricity take off"
 *  }
 * }
 * * Example After: {
 * *   Elektriciteit: [
 * *     {
 * *       ALLOCATION_POINT_FEED_IN_VOLUME: {
 * *         label: "Teruglevering elektriciteit (kWh)",
 * *         classes: "text-right",
 * *       },
 * *     },
 * *     {
 * *       ALLOCATION_POINT_TAKE_OFF_VOLUME: {
 * *         label: "Levering elektriciteit (kWh)",
 * *         classes: "text-right",
 * *       },
 * *     },
 * *   ]
 * * }
 * @returns {{}}
 */
function getGroupedColumns() {
  const enhancedResults = enhanceResults()
  return filterStore.useLegacyResults
    ? entriesByGroup(enhancedResults)
    : sortEntriesByOrderedGroupMapping(enhancedResults)
}

/**
 * This method is used in the 'groupColumns' method. Here the groups, labels,
 * wikiPath and styles are added,
 * @returns {{}}
 */
function enhanceResults() {
  const results = {}
  const { types } = recordsData.value

  Object.entries(types).forEach(([recordType, spec]) => {
    const group = findGroupForRecordType(recordType)
    results[group] = results[group] || {}

    const resultsStyling = partialResultColumns.includes(recordType)
      ? "font-bold text-primary"
      : "text-gray-500"
    const styling = recordType.includes("TOTAL")
      ? "bg-primary-100"
      : resultsStyling
    let textPlace = ""
    if (modeHiddenColumns[props.mode].includes(spec.unit)) {
      //The record type should not be in the results for this mode
      delete results[group][recordType]
    } else {
      textPlace = "text-right"
      results[group][recordType] = {
        label: `${spec.label.nl} (${spec.unit})`,
        classes: `${styling}  ${textPlace}`,
        wikiPath: recordTypeWikiMap[recordType],
      }
    }
  })

  return results
}

function findGroupForRecordType(recordType) {
  return (
    Object.keys(orderedGroupMapping).find((category) =>
      orderedGroupMapping[category].includes(recordType),
    ) || "Unknown"
  )
}

/**
 * This method is used in the 'groupColumns' method. This method set the record
 * types in the correct group order It also only uses the record types that are
 * in the 'orderedGroupMapping' and removes empty types
 * @returns {{}}
 */
function sortEntriesByOrderedGroupMapping(enhancedResults) {
  return Object.fromEntries(
    Object.entries(orderedGroupMapping)
      .map(([group, types]) => [
        group,
        types
          .filter((type) => enhancedResults[group]?.[type])
          .map((type) => ({ type, ...enhancedResults[group][type] })),
      ])
      .filter(([_, types]) => types.length > 0), // Remove empty groups
  )
}

/**
 * Yields analogously structured object to 'sortEntriesByOrderedGroupMapping',
 * but neither sorts nor filters entries. Used for processing legacy results.
 * @returns {{}}
 */
function entriesByGroup(enhancedResults) {
  return Object.fromEntries(
    Object.entries(enhancedResults).map(([group, types]) => [
      group,
      Object.keys(types).map((type) => ({
        type,
        ...enhancedResults[group][type],
      })),
    ]),
  )
}

const precisionColumns = computed(() => {
  if (!recordsData.value) {
    return []
  }
  return Object.entries(recordsData.value.types)
    .filter(([_, type]) => type.unit === "€/kWh")
    .map(([key]) => key)
})

const recordTypeWikiMap = {
  ALLOCATION_POINT_FEED_IN_VOLUME:
    "/platform/results/allocation-point-feed-in-volume",
  ALLOCATION_POINT_TAKE_OFF_VOLUME:
    "/platform/results/allocation-point-take-off-volume",
  ASSET_FEED_IN_VOLUME: "/platform/results/asset-feed-in-volume",
  ASSET_TAKE_OFF_VOLUME: "/platform/results/asset-take-off-volume",
  DAY_AHEAD_FEED_IN_PRICE: "/platform/results/day-ahead-feed-in-price",
  DAY_AHEAD_FEED_IN_RESULT: "/platform/results/day-ahead-feed-in-result",
  DAY_AHEAD_FEED_IN_VOLUME: "/platform/results/day-ahead-feed-in-volume",
  DAY_AHEAD_TAKE_OFF_PRICE: "/platform/results/day-ahead-take-off-price",
  DAY_AHEAD_TAKE_OFF_RESULT: "/platform/results/day-ahead-take-off-result",
  DAY_AHEAD_TAKE_OFF_VOLUME: "/platform/results/day-ahead-take-off-volume",
  ENERGY_SERVICE_FEE: "/platform/results/energy-service-fee",
  ENERGY_TAX: "/platform/results/energy-tax",
  FIXED_FEE_RESULT: "/platform/results/fixed-fee-result",
  MISSED_GVO: "/platform/results/missed-gvo",
  MISSED_SDE: "/platform/results/missed-sde",
  PORTFOLIO_BENEFIT: "/platform/results/portfolio-benefit",
  PORTFOLIO_IMBALANCE_FEED_IN_PRICE:
    "/platform/results/portfolio-imbalance-feed-in-price",
  PORTFOLIO_IMBALANCE_FEED_IN_RESULT:
    "/platform/results/portfolio-imbalance-feed-in-result",
  PORTFOLIO_IMBALANCE_FEED_IN_VOLUME:
    "/platform/results/portfolio-imbalance-feed-in-volume",
  PORTFOLIO_IMBALANCE_TAKE_OFF_PRICE:
    "/platform/results/portfolio-imbalance-take-off-price",
  PORTFOLIO_IMBALANCE_TAKE_OFF_RESULT:
    "/platform/results/portfolio-imbalance-take-off-result",
  PORTFOLIO_IMBALANCE_TAKE_OFF_VOLUME:
    "/platform/results/portfolio-imbalance-take-off-volume",
  SMART_ENERGY_BATTERY_FEE: "/platform/results/smart-energy-battery-fee",
  SMART_ENERGY_BATTERY_FEED_IN_RESULT:
    "/platform/results/smart-energy-battery-feed-in-result",
  SMART_ENERGY_BATTERY_FEED_IN_VOLUME:
    "/platform/results/smart-energy-battery-feed-in-volume",
  SMART_ENERGY_BATTERY_TAKE_OFF_RESULT:
    "/platform/results/smart-energy-battery-take-off-result",
  SMART_ENERGY_BATTERY_TAKE_OFF_VOLUME:
    "/platform/results/smart-energy-battery-take-off-volume",
  SMART_ENERGY_SOLAR_FEE: "/platform/results/smart-energy-solar-fee",
  SMART_ENERGY_SOLAR_FEED_IN_RESULT:
    "/platform/results/smart-energy-solar-feed-in-result",
  SMART_ENERGY_SOLAR_FEED_IN_VOLUME:
    "/platform/results/smart-energy-solar-feed-in-volume",
  SMART_ENERGY_SOLAR_TAKE_OFF_RESULT:
    "/platform/results/smart-energy-solar-take-off-result",
  SMART_ENERGY_SOLAR_TAKE_OFF_VOLUME:
    "/platform/results/smart-energy-solar-take-off-volume",
  TOTAL_RESULT: "/platform/results/total-result",
}

/**
 * Flattens a record entry to pass to the BaseTable component's 'row' prop.
 * Before:
 *   [
 *     "2000-01-01T00:00:00.000Z",
 *     {
 *       "data": {
 *         "Type A": {
 *           "value": 0,
 *           "stable": true
 *         },
 *         "Type B": {
 *           "value": 0,
 *           "stable": true
 *         },
 *       },
 *     },
 *   ]
 * After:
 *   {
 *     _id: "cell",
 *     datetime: new Date("2000-01-01T00:00:00.000Z"),
 *     stable: true,
 *     "Type A": 0,
 *     "Type B": 0,
 *   }
 */
function flattenRecord(entry) {
  const [key, { data }] = entry
  const values = {}
  let stable = true
  for (const [recordType, content] of Object.entries(data)) {
    values[recordType] = content.value
    stable = stable && content.stable
  }
  return {
    _id: key === "Totaal" ? "summary" : "cell",
    datetime: key === "Totaal" ? key : new Date(key),
    stable,
    ...values,
  }
}

const rows = computed(() => {
  if (!recordsData.value) {
    return []
  }
  const { records, totals } = recordsData.value
  return [
    flattenRecord(["Totaal", totals]),
    ...Object.entries(records).map(flattenRecord),
  ]
})

function onNewRecords(newRecords) {
  recordsData.value = newRecords
  loading.value = false
}

function onLoadRecordsFailed(error) {
  loadingFailed.value = true
  loading.value = false
  notificationStore.pushError(
    "Fout bij het ophalen van resultaten",
    `De resultaten konden niet worden opgehaald. Probeer het later opnieuw. (code: ${error?.code})`,
    "load-records-error",
  )
}

const loadData = debounce(() => {
  props.meteringPoint?.loadResults(
    freq[step.value],
    props.dateRange,
    false,
    onNewRecords,
    onLoadRecordsFailed,
  )
}, DEBOUNCE_DELAY)

watch(
  [() => props.meteringPoint, () => props.dateRange],
  () => {
    recordsData.value = null
    if (!props.meteringPoint) {
      return
    }
    loading.value = true
    loadingFailed.value = false
    loadData()
  },
  { immediate: true },
)
</script>
