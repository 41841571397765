// Should be replaced byVue i18n or something similar

export default function getPhrase(key) {
  if (key in phrases) {
    return phrases[key]
  } else {
    console.warn(`Phrase with key "${key}" not found`)
    return key
  }
}

const phrases = {
  "status-unknown": "Asset status onbekend",
  "status-offline": "Asset is offline",
  "status-online": "Asset is online",
  "status-active-steering-curtailment": "Asset wordt gecurtaild",
  "status-active-steering-charging": "Asset is aan het opladen",
  "status-active-steering-discharging": "Asset is aan het ontladen",
  "status-active-steering-not-specified": "Asset wordt aangestuurd",
  "status-error": "Er is een fout opgetreden",

  "demo-disclaimer":
    "De getoonde resultaten zijn gebaseerd op simulaties met historische data om de services van Repowered te demonstreren.",

  "toast-breakpoint-limit":
    "Data van vóór 2025 kan niet tegelijk met nieuwe data worden getoond i.v.m. verschillende systemen. Maak een nieuwe selectie in een van beide periodes om meer resultaten te zien.",
  "toast-breakpoint-asset":
    "Resultaten op assetniveau zijn niet beschikbaar vóór 2025. Kies een hoger niveau om meer resultaten te zien.",
}
