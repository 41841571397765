<template>
  <div class="flex flex-col gap-4">
    <!-- Header -->
    <TextHeader>Resultaten</TextHeader>
    <!-- Filter bar -->
    <ToolbarComponent
      with-portfolio
      :no-assets="filterStore.useLegacyResults"
      :datepicker-breakpoints="[config.LEGACY_RESULTS_BREAKPOINT]"
      @breakpoint-limit="onDateBreakpointLimit"
    >
      <ExportComponent
        :metering-point="filterStore.currentMeteringPoint"
        :date-range="filterStore.dateRange"
        type="results"
      />
    </ToolbarComponent>
    <MessageComponent
      v-if="filterStore.currentMeteringPoint?.demo"
      color="info"
    >
      {{ $p("demo-disclaimer") }}
    </MessageComponent>
    <!-- Chart panel -->
    <ResultsChartPanel
      :metering-point="filterStore.currentMeteringPoint"
      :date-range="filterStore.dateRange"
      :mode="filterStore.mode"
    />
    <!-- Table panel -->
    <BaseDivider />
    <ResultsTablePanel
      :metering-point="filterStore.currentMeteringPoint"
      :date-range="filterStore.dateRange"
      :mode="filterStore.mode"
    />
  </div>
</template>

<script setup>
import config from "/config.js"
import { watch, onBeforeMount, onUnmounted } from "vue"
import { startOfToday, startOfMonth, lastDayOfMonth } from "date-fns"
import useFilterStore from "@/stores/filterStore.js"
import useNotificationStore from "@/stores/notificationStore.js"
import { ASSET } from "@/stores/repower/meteringPointStoreFactories"
import $p from "@/services/phraseService.js"
import { TextHeader, BaseDivider } from "@repowerednl/ui-component-library"
import ToolbarComponent from "@/components/navigation/ToolbarComponent.vue"
import MessageComponent from "@/components/information/MessageComponent.vue"
import ExportComponent from "@/components/information/ExportComponent.vue"
import ResultsChartPanel from "@/components/panels/ResultsChartPanel.vue"
import ResultsTablePanel from "@/components/panels/ResultsTablePanel.vue"

const filterStore = useFilterStore()
const notificationStore = useNotificationStore()

const bannerUid = notificationStore.pushContentBanner(
  {
    title: "Per 2025 werken we met een nieuw systeem voor resultaten",
    message: "Neem contact op bij onverwachte of afwijkende resultaten",
    action: () => window.open("mailto:support@repowered.nl", "_self"),
    actionLabel: "Contact",
  },
  "new-records-system",
)

function onDateBreakpointLimit() {
  notificationStore.pushToast(
    "Datumselectie begrensd",
    $p("toast-breakpoint-limit"),
    "datepicker-breakpoint",
  )
}

onBeforeMount(() => {
  filterStore.setDateRange([
    startOfMonth(startOfToday()),
    lastDayOfMonth(startOfToday()),
  ])
  if (!filterStore.currentMeteringPoint) {
    filterStore.setMeteringPoint(filterStore.currentPortfolio)
  }
})

onUnmounted(() => {
  notificationStore.remove(bannerUid)
})

/**
 * Clear an asset selection when switching to legacy results
 */
watch(
  () => filterStore.useLegacyResults,
  (useLegacyResults) => {
    if (useLegacyResults && filterStore.currentMeteringPoint?.role === ASSET) {
      filterStore.clearMeteringPoint()
      notificationStore.pushToast(
        "Assetresultaten niet beschikbaar",
        $p("toast-breakpoint-asset"),
        "breakpoint-asset",
      )
    }
  },
)
</script>
