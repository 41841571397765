import config from "/config.js"
import { defineStore } from "pinia"
import { startOfToday } from "date-fns"
import useMeteringPointsStore from "@/stores/repower/meteringPointsStore.js"

export default defineStore("filterStore", {
  state: () => ({
    currentMeteringPoint: null,
    currentPortfolio: null,
    dateRange: [startOfToday(), startOfToday()],
    mode: "energy",
  }),
  getters: {
    currentPortfolioAllocationPoints() {
      return (
        useMeteringPointsStore().allocationPointsByPortfolio[
          this.currentPortfolio?.id
        ] ?? []
      )
    },
    currentPortfolioAssets() {
      return (
        useMeteringPointsStore().assetsByPortfolio[this.currentPortfolio?.id] ??
        []
      )
    },
    useLegacyResults(state) {
      return state.dateRange[0] < config.LEGACY_RESULTS_BREAKPOINT
    },
  },
  actions: {
    setDateRange(dateRange) {
      this.dateRange = dateRange
    },
    setMode(mode) {
      this.mode = mode
    },
    setMeteringPoint(meteringPoint) {
      this.currentMeteringPoint = meteringPoint
    },
    clearMeteringPoint() {
      this.currentMeteringPoint = null
    },
    setPortfolio(portfolio) {
      this.currentPortfolio = portfolio
    },
  },
})
