<template>
  <div class="flex flex-col gap-4">
    <!-- Header -->
    <TextHeader class="border-none pb-0">Assets</TextHeader>
    <TabsComponent :tabs />
    <ToolbarComponent
      no-allocation-points
      uncollapsed
      no-mode
      :no-datepicker="$route.name !== 'assets-analysis'"
    >
      <ExportComponent
        v-if="$route.name === 'assets-analysis'"
        :metering-point="filterStore.currentMeteringPoint"
        :date-range="filterStore.dateRange"
        type="measurements"
      />
    </ToolbarComponent>
    <MessageComponent
      v-if="filterStore.currentMeteringPoint?.demo"
      color="info"
    >
      {{ $p("demo-disclaimer") }}
    </MessageComponent>
    <RouterView />
  </div>
</template>

<script setup>
import { onBeforeMount } from "vue"
import { startOfToday } from "date-fns"
import $p from "@/services/phraseService.js"
import { ASSET } from "@/stores/repower/meteringPointStoreFactories.js"
import { TextHeader } from "@repowerednl/ui-component-library"
import useFilterStore from "@/stores/filterStore.js"
import TabsComponent from "@/components/navigation/TabsComponent.vue"
import ToolbarComponent from "@/components/navigation/ToolbarComponent.vue"
import ExportComponent from "@/components/information/ExportComponent.vue"
import MessageComponent from "@/components/information/MessageComponent.vue"

const filterStore = useFilterStore()

const tabs = [
  {
    name: "Analyse",
    route: "assets-analysis",
  },
  {
    name: "Info",
    route: "assets-information",
  },
  {
    name: "Diensten",
    route: "assets-services",
  },
]

onBeforeMount(() => {
  if (filterStore.currentMeteringPoint?.role !== ASSET) {
    filterStore.clearMeteringPoint()
  }
  filterStore.setDateRange([startOfToday(), startOfToday()])
})
</script>
