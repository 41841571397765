<template>
  <div
    class="relative box-content h-16 place-content-center border-b border-gray-100 bg-white"
  >
    <!-- Asset button row -->
    <div class="relative h-full flex-1 overflow-hidden">
      <AssetsBarComponent
        :assets="
          meteringPointsStore.assetsByPortfolio[
            filterStore.currentPortfolio?.id
          ] ?? []
        "
        :clickable="true"
        :loading="meteringPointsStore.loadingAssets"
        show-search
        class="pl-4 md:pl-10"
      />
    </div>
  </div>
</template>

<script setup>
import useFilterStore from "@/stores/filterStore.js"
import useMeteringPointsStore from "@/stores/repower/meteringPointsStore.js"
import AssetsBarComponent from "@/components/navigation/AssetsBarComponent.vue"

const filterStore = useFilterStore()
const meteringPointsStore = useMeteringPointsStore()
</script>
