<template>
  <div class="w-full gap-4 md:flex">
    <!-- Asset selection -->
    <FilterMenuComponent
      ref="filterMenuRef"
      v-model="filterStore.currentMeteringPoint"
      :items="filterItems"
      :all-open="uncollapsed"
      :shaded-overlay="'search' in $route.query"
      @confirmed="onFilterConfirmed"
      @cancelled="onFilterCancelled"
    >
      <!-- Add extra top-level portfolio item at the top -->
      <template #[`POR_${filterStore.currentPortfolio?.name}`]>
        <div class="flex items-center gap-1.5">
          <BaseBadge v-if="filterStore.currentPortfolio.demo" color="gray">
            Demo
          </BaseBadge>
          <BaseIcon name="briefcase-4" filled class="text-lg" />
          <span>{{ filterStore.currentPortfolio.name }}</span>
        </div>
      </template>
      <!-- Undo allocation point label prefix -->
      <template
        v-for="allocationPoint in filterStore.currentPortfolioAllocationPoints"
        :key="allocationPoint.id"
        #[`AP_${allocationPoint.name}`]
      >
        <div class="flex items-center gap-1.5">
          <BaseBadge v-if="allocationPoint.demo" color="gray"> Demo </BaseBadge>
          <span>{{ allocationPoint.name }}</span>
        </div>
      </template>
      <!-- Enrich asset names with status and icon -->
      <template
        v-for="asset in filterStore.currentPortfolioAssets"
        :key="asset.id"
        #[asset.name]
      >
        <div class="flex items-center gap-1.5">
          <StatusIndicatorComponent :asset />
          <BaseBadge v-if="asset.demo" color="gray"> Demo </BaseBadge>
          <span class="truncate">{{ asset.name }}</span>
        </div>
      </template>
    </FilterMenuComponent>
    <!-- Date selection -->
    <DatePicker
      v-if="!noDatepicker"
      v-model="filterStore.dateRange"
      :breakpoints="datepickerBreakpoints"
      teleport="#main-layout"
      class="my-2 md:my-0 md:w-80"
      @breakpoint-limit="(value) => $emit('breakpoint-limit', value)"
    />
    <!-- Filler -->
    <div class="flex-1" />
    <!-- Mode selection -->
    <RadioButton
      v-if="!noMode"
      :initial-value="filterStore.mode"
      class="mb-2 grid grid-cols-3 md:mb-0 md:flex"
      :items="radioItems"
      @selection-changed="onModeChanged"
    >
      <template #energy="{ label }">
        <BaseIcon name="flashlight" filled class="mr-1" />
        {{ label }}
      </template>
      <template #finance="{ label }">
        <BaseIcon name="money-euro-circle" filled class="mr-1 text-lg" />
        {{ label }}
      </template>
    </RadioButton>
    <!-- Other buttons -->
    <slot />
  </div>
</template>

<script setup>
import { ref, computed, watch } from "vue"
import { useRoute, useRouter } from "vue-router"
import useFilterStore from "@/stores/filterStore.js"
import {
  BaseIcon,
  BaseBadge,
  DatePicker,
  RadioButton,
} from "@repowerednl/ui-component-library"
import FilterMenuComponent from "@/components/input/FilterMenuComponent.vue"
import StatusIndicatorComponent from "@/components/information/StatusIndicatorComponent.vue"

const props = defineProps({
  datepickerBreakpoints: {
    type: Array,
    default: () => [],
  },
  noAllocationPoints: Boolean,
  noAssets: Boolean,
  uncollapsed: Boolean,
  withPortfolio: Boolean,
  noDatepicker: Boolean,
  noMode: Boolean,
})

defineEmits(["breakpoint-limit"])

const route = useRoute()
const router = useRouter()
const filterStore = useFilterStore()
const filterMenuRef = ref()

const filterItems = computed(() => {
  // Prefix allocation points labels to distinguish from assets that have the
  // same name
  const allocationPointItems = filterStore.currentPortfolioAllocationPoints.map(
    (allocationPoint) => {
      return {
        label: `AP_${allocationPoint.name}`,
        value: allocationPoint,
        disabled: props.noAllocationPoints,
      }
    },
  )
  const assetItems = filterStore.currentPortfolioAssets.map((asset) => {
    const allocationPoint = filterStore.currentPortfolioAllocationPoints.find(
      (ap) => ap.id === asset.allocationPointId,
    )
    return {
      label: asset.name,
      value: asset,
      disabled: props.noAssets,
      parent: allocationPoint ? `AP_${allocationPoint.name}` : null,
    }
  })
  if (props.withPortfolio && filterStore.currentPortfolio) {
    return [
      {
        label: `POR_${filterStore.currentPortfolio.name}`,
        value: filterStore.currentPortfolio,
      },
      ...allocationPointItems,
      ...assetItems,
    ]
  } else {
    return [...allocationPointItems, ...assetItems]
  }
})

const radioItems = computed(() => [
  { label: "Energie", value: "energy" },
  { label: "Financieel", value: "finance" },
  { label: "Beide", value: "both" },
])

function onFilterConfirmed() {
  router.replace({ query: null })
}

function onFilterCancelled() {
  router.replace({ query: null })
}

function onModeChanged(selection) {
  filterStore.setMode(selection)
}

/**
 * Open the menu when the 'search' URL query is present
 */
watch(
  [() => route.query, () => filterMenuRef.value],
  ([query, menuRef]) => {
    if (menuRef && "search" in query) {
      filterMenuRef.value.openMenu()
    }
  },
  { immediate: true },
)
</script>
