<template>
  <BaseCard class="flex flex-col gap-2 p-4 md:p-6">
    <div v-if="meteringPoint">
      <BaseButton
        v-if="showTitle"
        narrow
        color="subtle"
        class="-mx-2"
        @click="onTitleClicked"
      >
        <div class="flex items-center gap-1.5">
          <StatusIndicatorComponent :asset="meteringPoint" />
          <BaseBadge v-if="meteringPoint.demo" color="gray"> Demo </BaseBadge>
          <TextSubtitle>{{ meteringPoint.name }}</TextSubtitle>
        </div>
      </BaseButton>
      <slot />
    </div>
    <div
      v-else
      class="flex h-full items-center justify-center p-4 italic md:p-6"
    >
      <p>Maak een selectie om gegevens te kunnen bekijken</p>
    </div>
  </BaseCard>
</template>

<script setup>
import { useRouter } from "vue-router"
import { startOfToday } from "date-fns"
import useFilterStore from "@/stores/filterStore.js"
import {
  BaseCard,
  BaseButton,
  BaseBadge,
  TextSubtitle,
} from "@repowerednl/ui-component-library"
import StatusIndicatorComponent from "@/components/information/StatusIndicatorComponent.vue"

const props = defineProps({
  meteringPoint: {
    type: Object,
    default: null,
  },
  showTitle: Boolean,
})

const router = useRouter()
const filterStore = useFilterStore()

function onTitleClicked() {
  if (props.meteringPoint) {
    filterStore.setMeteringPoint(props.meteringPoint)
    filterStore.setDateRange([startOfToday(), startOfToday()])
    router.push({ name: "assets-analysis" })
  }
}
</script>
