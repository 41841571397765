<template>
  <BaseDrawer>
    <template #trigger>
      <BaseTooltip :description="description" v-bind="$attrs">
        <BaseIcon
          name="information"
          filled
          class="cursor-pointer text-gray-300 hover:text-primary"
        />
      </BaseTooltip>
    </template>
    <template #content>
      <TextDrawerTitle class="border-b border-gray-100 p-4 md:p-6">
        <BaseIcon name="information" filled class="mb-1" />
        {{ title }}
      </TextDrawerTitle>
      <div class="p-4 text-base font-normal text-black md:p-6">
        <!-- Risks due to v-html are mitigated with DOMPurify -->
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div class="wiki" v-html="content" />
      </div>
    </template>
  </BaseDrawer>
</template>

<script setup>
import { ref, onMounted } from "vue"
import useWikiStore from "@/stores/wikiStore.js"
import useNotificationStore from "@/stores/notificationStore.js"
import {
  BaseIcon,
  TextDrawerTitle,
  BaseDrawer,
  BaseTooltip,
} from "@repowerednl/ui-component-library"

const props = defineProps({
  path: {
    type: String,
    required: true,
  },
})

defineOptions({
  inheritAttrs: false,
})

const LOADING_MESSAGE = "Documentatie laden..."

const wikiStore = useWikiStore()
const notificationStore = useNotificationStore()
const title = ref(LOADING_MESSAGE)
const description = ref(LOADING_MESSAGE)
const content = ref(LOADING_MESSAGE)

onMounted(() => {
  wikiStore.loadPage(
    props.path,
    (page) => {
      title.value = page.title
      description.value = page.description
      content.value = page.content
    },
    (error) => {
      notificationStore.pushError(
        "Documentatie niet beschikbaar",
        `Sommige beschrijvingen konden niet worden opgehaald (code: ${error.code})`,
        "loading-wiki-failed",
      )
    },
  )
})
</script>

<!-- Global style to make it work with rendered HTML -->
<style>
.wiki h1 {
  @apply mb-2 mt-6 border-b border-gray-200 pb-2 text-2xl font-normal;
}

.wiki h2 {
  @apply mb-1 mt-4 text-xl font-medium;
}

.wiki h3 {
  @apply mb-1 mt-2 text-lg font-bold;
}

.wiki p {
  @apply my-1;
}

.wiki ul {
  @apply my-1 list-disc pl-4 marker:text-primary;
}

.wiki ol {
  @apply my-1 list-decimal pl-4 marker:text-primary;
}

.wiki img {
  @apply my-4 w-full rounded-2xl border border-gray-200 shadow;
}

.wiki a {
  @apply underline decoration-primary decoration-2 hover:text-primary;
}
</style>
