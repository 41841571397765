<template>
  <div class="flex flex-col gap-4">
    <div class="flex flex-wrap items-center justify-between">
      <TextTitle class="mb-2 md:mb-0">
        <template v-if="filterStore.currentPortfolio">
          {{ filterStore.currentPortfolio.name }} facturen
        </template>
        <template v-else> Kies een portfolio </template>
      </TextTitle>
      <DatePicker
        v-model="invoicesStore.dateRange"
        year-picker
        class="mb-2 w-40 md:mb-0"
      />
    </div>
    <BaseCard class="max-h-[500px] overflow-x-auto">
      <LoadingWrapper
        :loading="invoicesStore.loading"
        :error="invoicesStore.loadingFailed"
      >
        <BaseTable :columns="columns" :rows="rows">
          <template #invoice_number-cell="{ row }">
            <div class="flex items-center gap-2">
              {{ row.invoice_number }}
              <BaseBadge v-if="row.status === 'paid'">Betaald</BaseBadge>
            </div>
          </template>
          <template #download-cell="{ row }">
            <BaseButton
              small
              class="ml-auto"
              :loading="loadingPdf === row.invoice_number"
              :disabled="loadingPdf && loadingPdf !== row.invoice_number"
              @click="onInvoiceDownload(row.invoice_number)"
            >
              <BaseIcon name="download-2" class="m-1" />
              Download
            </BaseButton>
          </template>
        </BaseTable>
      </LoadingWrapper>
    </BaseCard>
  </div>
</template>

<script setup>
import { ref, computed, watch } from "vue"
import { format, sub } from "date-fns"
import base64Download from "@/composables/base64Download.js"
import { formatCurrency } from "@/services/formatterService.js"
import useFilterStore from "@/stores/filterStore.js"
import useInvoicesStore from "@/stores/repower/invoicesStore.js"
import useNotificationStore from "@/stores/notificationStore.js"
import {
  TextTitle,
  BaseCard,
  BaseButton,
  BaseIcon,
  BaseBadge,
  BaseTable,
  DatePicker,
  LoadingWrapper,
} from "@repowerednl/ui-component-library"

const filterStore = useFilterStore()
const invoicesStore = useInvoicesStore()
const notificationStore = useNotificationStore()
const loadingPdf = ref(false)

const columns = {
  invoice_number: {
    label: "Factuurnummer",
    rawNumbers: true,
    classes: "tabular-nums",
  },
  period: {
    label: "Periode",
  },
  date: {
    label: "Datum",
    classes: "text-right",
  },
  amount: {
    label: "Bedrag",
    classes: "text-right tabular-nums",
  },
  download: {
    label: "Download",
    classes: "!py-0 text-right",
  },
}

/**
 * Invoices for which the period is not the month before the invoice date. Maps
 * invoice number to the period that should be displayed.
 *
 * TODO: Let period overrides be specified from the invoice in Yoobi (REP-3980)
 */
const periodOverrideMap = {
  25702328: "september 2024",
  25702330: "augustus 2024",
  25702329: "juli 2024",
  25702327: "juni 2024",
}

const rows = computed(() => {
  return invoicesStore.invoices.map((row) => ({
    ...row,
    date: format(new Date(row.date), "dd-MM-yyyy"),
    amount: formatCurrency(row.amount),
    period:
      periodOverrideMap[row.invoice_number] ??
      format(sub(new Date(row.date), { months: 1 }), "MMMM yyyy"),
    _id: "cell",
  }))
})

function onInvoiceDownload(invoiceNumber) {
  loadingPdf.value = invoiceNumber
  invoicesStore.getInvoicePdf(
    filterStore.currentPortfolio.id,
    invoiceNumber,
    ({ data }) => {
      base64Download(
        data,
        "pdf",
        `Repowered - ${filterStore.currentPortfolio.name} factuur ${invoiceNumber}.pdf`,
      )
      loadingPdf.value = false
    },
    (error) => {
      notificationStore.pushError(
        "Fout bij het downloaden van factuur",
        `Factuur ${invoiceNumber} kon niet worden gedownload. Probeer het later opnieuw. (code: ${error.code})`,
        "download-invoice-error",
      )
      loadingPdf.value = false
    },
  )
}

watch(
  [() => filterStore.currentPortfolio, () => invoicesStore.dateRange],
  ([portfolio, _]) => {
    if (portfolio) {
      invoicesStore.loadInvoices(portfolio.id, null, (error) =>
        notificationStore.pushError(
          "Fout bij het ophalen van facturen",
          `De facturen konden niet worden opgehaald. Probeer het later opnieuw. (code: ${error.code})`,
          "load-invoices-error",
        ),
      )
    } else {
      invoicesStore.invoices = []
    }
  },
  { immediate: true },
)
</script>
